@import "../global/variables";

.offcanvas {
  background-color: $color-dark-grey !important;

  .btn-close {
    color: $color-white;
    filter: brightness(0) invert(1);
  }

  .dropdown-menu {
    background-color: #222;
    border: var(--bs-dropdown-border-width) solid rgba(171, 144, 206, 0.3);

    .dropdown-divider {
      border-top-color: rgba(171, 144, 206, 0.3);
    }

    .dropdown-item {
      color: $color-white;

      &:focus, &:active, &:hover, &.active {
        color: $brand-tertiary-color;
        background-color: transparent;
      }
    }
  }
}

nav {
  background-color: $brand-dark-color;
  border-bottom: 2px solid #555555;

  &.navbar-light {
    &.navbar-expand-lg .navbar-nav .nav-link,
    .navbar-brand {
      color: $color-white;
    }

    .navbar-toggler-icon {
      filter: brightness(0) invert(1);
    }
  }
}
