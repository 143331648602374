@import "global/variables";

$theme-colors: (
  'primary': $brand-secondary-color,
  'secondary': $brand-primary-color,
  'tertiary': $brand-tertiary-color,
  'danger': $brand-danger-color,
  'success': $brand-success-color,
  'warning': $brand-warning-color,
  'info': $brand-info-color,
  'light': $brand-light-color,
  'dark': $brand-dark-color,
);

@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: $font-family;
  font-display: swap;
}

html, body {
  font-family: $font-family;
  font-display: swap;
  font-weight: 300;
  min-height: 100%;
  color: $color-white;
  background-color: $color-dark-grey;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
}

i {
  font-style: normal;

  &:not(.fa-*) {
    font-style: italic;
  }
}

#root {
  min-height: 100%;
}

a {
  color: $brand-tertiary-color;
  text-decoration: none;

  &:hover {
    color: $color-white;
  }
}

.text-right {
  text-align: right;
}

.text-primary {
  color: $brand-primary-color !important;
}
.text-secondary {
  color: $brand-secondary-color !important;
}
.text-tertiary {
  color: $brand-tertiary-color !important;
}
.text-grey {
  color: $color-grey;
}
.text-white {
  color: $color-white;
}

.text-linethrough {
  background: linear-gradient(to left top, transparent 46.75%, currentColor 49.5%, currentColor 50.5%, transparent 53.25%);
}

.primary {
  background-color: $brand-primary-color !important;
}
.secondary {
  background-color: $brand-secondary-color !important;
}
.tertiary {
  background-color: $brand-tertiary-color !important;
}
.grey {
  background-color: $color-grey;
}

.fs-7 {
  font-size: 0.9rem !important;
}
.fs-8 {
  font-size: 0.8rem !important;
}

.valign-middle {
  vertical-align: middle;
}

.section-grey {
  background-color: $brand-dark-color;
}
.section-light-grey {
  background-color: #424242;
}

h1 {
  color: $brand-tertiary-color;

  .page_not_found {
    display: block;
    font-size: 120px;
    background: -webkit-linear-gradient($brand-tertiary-color, $brand-secondary-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

h2 {
  color: $brand-primary-color;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .checkmark {
    position: relative;
    height: 25px;
    width: 25px;
    display: block;
    background-color: #eeeeee;

    &:after {
      position: absolute;
      display: none;
      left: 9px;
      top: 4px;
      width: 7px;
      height: 14px;
      content: '';
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.checked {
      background-color: #2196F3;

      &:after {
        display: block;
      }
    }
  }

  &:hover .checkmark {
    background-color: #aaaaaa;
  }
}

.accordion {
  --bs-body-color: #{$color-white};
  --bs-accordion-color: #{$color-grey};
  --bs-accordion-bg: rgba(0, 0, 0, 0.2);
  --bs-accordion-active-color: #{$brand-tertiary-color};
  --bs-accordion-active-bg: rgba(0, 0, 0, 0.3);
  --bs-accordion-border-color: #{$brand-dark-color};
  --bs-accordion-btn-bg: rgba(0, 0, 0, 0.2);
  --bs-accordion-btn-focus-border-color: #{$brand-tertiary-color};
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(173 144 207, 0.25);

  .accordion-button {
    &:after {
      filter: invert(100%);
    }
    &:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-icon);
      transform: var(--bs-accordion-btn-icon-transform);
    }
  }
}

.bold {
  font-weight: 500;
}

.card {
  --bs-card-bg: #1B1B1B;
}

a.card:hover {
  --bs-card-bg: #151515;
}
