@import "../../scss/global/variables";

footer.footer {
  padding: 0 12px;
  box-sizing: border-box;
  background-color: $brand-dark-color;

  .bottom {
    background-color: #424242;
  }

  .listGroupItemTertiary {
    font-size: 14px;
    color: $color-white;
    background-color: transparent;

    &:hover {
      color: $brand-tertiary-color;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
