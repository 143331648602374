@import "colours";

$font-family: 'Roboto', sans-serif;

$brand-primary-color: #2c0a3e;
$brand-secondary-color: #623e8c;
$brand-tertiary-color: #ad90cf;
$brand-danger-color: #dc3545;
$brand-success-color: #4CAF50;
$brand-warning-color: #ffc107;
$brand-info-color: #17a2b8;
$brand-light-color: #f8f9fa;
$brand-dark-color: #333333;

$color-white: #ffffff;
$color-black: #000000;
$color-dark-grey: #222222;
$color-grey: #9e9e9e;
